import { CompositeTilemap } from "@pixi/tilemap";
import { Container, Assets, AlphaFilter } from "pixi.js";
import { Spine } from "pixi-spine";
import type TiledMap from "tiled-tmj-typedefs/types/TiledMap";
import { Sprite, Texture } from "pixi.js";
import { baseName } from "~/composables/utils/utils";

export const ANIMATED_ASSETS = [
    {
        name: "Ship_battle",
        animations: [
            { value: "0", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "1", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "2", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "3", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "4", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "5", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "6", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "7", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "8", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "9", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "10", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "11", animation: ["ships-battle-Fix-fire", "ships-battle-end"] },
            { value: "12", animation: ["ships-battle-Fix-fire", "ships-battle-end"] }
        ]
    },
    {
        name: "dock1",
        animations: [
            { value: "0", animation: ["flag-1-green"] },
            { value: "1", animation: ["flag-1-green"] },
            { value: "2", animation: ["flag-1-green"] },
            { value: "3", animation: ["flag-2-blue"] },
            { value: "4", animation: ["flag-2-blue"] },
            { value: "5", animation: ["flag-2-blue"] },
            { value: "6", animation: ["flag-3-yellow"] },
            { value: "7", animation: ["flag-3-yellow"] },
            { value: "8", animation: ["flag-3-yellow"] },
            { value: "9", animation: ["flag-4-red"] },
            { value: "10", animation: ["flag-4-red"] },
            { value: "11", animation: ["flag-5-black"] },
            { value: "12", animation: ["flag-5-black"] }
        ]
    },
    {
        name: "ship_1",
        animations: [
            { value: "0", animation: ["ship_1_idleFlag"] },
            { value: "1", animation: ["ship_1_idleFlag"] },
            { value: "2", animation: ["ship_1_idleFlag"] },
            { value: "3", animation: ["ship_1_idleFlag"] },
            { value: "4", animation: ["ship_1_idleFlag"] },
            { value: "5", animation: ["ship_1_idleFlag"] },
            { value: "6", animation: ["ship_1_idleFlag"] },
            { value: "7", animation: ["ship_1_idleFlag"] },
            { value: "8", animation: ["ship_1_idleFlag"] },
            { value: "9", animation: ["ship_1_idleFlag"] },
            { value: "10", animation: ["ship_1_idleFlag"] },
            { value: "11", animation: ["ship_1_idleFlag"] },
            { value: "12", animation: ["ship_1_idleFlag"] }
        ]
    },
    {
        name: "Ship_2",
        animations: [
            { value: "0", animation: ["ship_2_idleFlag"] },
            { value: "1", animation: ["ship_2_idleFlag"] },
            { value: "2", animation: ["ship_2_idleFlag"] },
            { value: "3", animation: ["ship_2_idleFlag"] },
            { value: "4", animation: ["ship_2_idleFlag"] },
            { value: "5", animation: ["ship_2_idleFlag"] },
            { value: "6", animation: ["ship_2_idleFlag"] },
            { value: "7", animation: ["ship_2_idleFlag"] },
            { value: "8", animation: ["ship_2_idleFlag"] },
            { value: "9", animation: ["ship_2_idleFlag"] },
            { value: "10", animation: ["ship_2_idleFlag"] },
            { value: "11", animation: ["ship_2_idleFlag"] },
            { value: "12", animation: ["ship_2_idleFlag"] }
        ]
    },
    {
        name: "Ship_3",
        animations: [
            { value: "0", animation: ["ship_3_workingFlag"] },
            { value: "1", animation: ["ship_3_workingFlag"] },
            { value: "2", animation: ["ship_3_workingFlag"] },
            { value: "3", animation: ["ship_3_workingFlag"] },
            { value: "4", animation: ["ship_3_workingFlag"] },
            { value: "5", animation: ["ship_3_workingFlag"] },
            { value: "6", animation: ["ship_3_workingFlag"] },
            { value: "7", animation: ["ship_3_workingFlag"] },
            { value: "8", animation: ["ship_3_workingFlag"] },
            { value: "9", animation: ["ship_3_idleSailsUpFlag"] },
            { value: "10", animation: ["ship_3_idleSailsUpFlag"] },
            { value: "11", animation: ["ship_3_idleSailsUpFlag"] },
            { value: "12", animation: ["ship_3_idleSailsUpFlag"] }
        ]
    },
    {
        name: "Ship_2_orange",
        animations: [
            { value: "0", animation: ["ship_2_workingFlag"] },
            { value: "1", animation: ["ship_2_workingFlag"] },
            { value: "2", animation: ["ship_2_workingFlag"] },
            { value: "3", animation: ["ship_2_workingFlag"] },
            { value: "4", animation: ["ship_2_workingFlag"] },
            { value: "5", animation: ["ship_2_workingFlag"] },
            { value: "6", animation: ["ship_2_workingFlag"] },
            { value: "7", animation: ["ship_2_workingFlag"] },
            { value: "8", animation: ["ship_2_workingFlag"] },
            { value: "9", animation: ["ship_2_idleSailsUpFlag"] },
            { value: "10", animation: ["ship_2_idleSailsUpFlag"] },
            { value: "11", animation: ["ship_2_idleSailsUpFlag"] },
            { value: "12", animation: ["ship_2_idleSailsUpFlag"] }
        ]
    },
    {
        name: "Boat_1",
        animations: [
            { value: "0", animation: ["boat-1-float"] },
            { value: "1", animation: ["boat-1-float"] },
            { value: "2", animation: ["boat-1-float"] },
            { value: "3", animation: ["boat-1-float"] },
            { value: "4", animation: ["boat-1-float"] },
            { value: "5", animation: ["boat-1-float"] },
            { value: "6", animation: ["boat-1-float"] },
            { value: "7", animation: ["boat-1-float"] },
            { value: "8", animation: ["boat-1-float"] },
            { value: "9", animation: ["boat-1-float"] },
            { value: "10", animation: ["boat-1-float"] },
            { value: "11", animation: ["boat-1-float"] },
            { value: "12", animation: ["boat-1-float"] }
        ]
    },
    {
        name: "raft-1",
        animations: [
            { value: "0", animation: ["raft-1-float"] },
            { value: "1", animation: ["raft-1-float"] },
            { value: "2", animation: ["raft-1-float"] },
            { value: "3", animation: ["raft-1-float"] },
            { value: "4", animation: ["raft-1-float"] },
            { value: "5", animation: ["raft-1-float"] },
            { value: "6", animation: ["raft-1-float"] },
            { value: "7", animation: ["raft-1-float"] },
            { value: "8", animation: ["raft-1-float"] },
            { value: "9", animation: ["raft-1-float"] },
            { value: "10", animation: ["raft-1-float"] },
            { value: "11", animation: ["raft-1-float"] },
            { value: "12", animation: ["raft-1-float"] }
        ]
    },
    {
        name: "boat-6",
        animations: [
            { value: "0", animation: ["boat-6-float"] },
            { value: "1", animation: ["boat-6-float"] },
            { value: "2", animation: ["boat-6-float"] },
            { value: "3", animation: ["boat-6-float"] },
            { value: "4", animation: ["boat-6-float"] },
            { value: "5", animation: ["boat-6-float"] },
            { value: "6", animation: ["boat-6-float"] },
            { value: "7", animation: ["boat-6-float"] },
            { value: "8", animation: ["boat-6-float"] },
            { value: "9", animation: ["boat-6-float"] },
            { value: "10", animation: ["boat-6-float"] },
            { value: "11", animation: ["boat-6-float"] },
            { value: "12", animation: ["boat-6-float"] }
        ]
    },
    {
        name: "Boat-7",
        animations: [
            { value: "0", animation: ["Boat-7-float"] },
            { value: "1", animation: ["Boat-7-float"] },
            { value: "2", animation: ["Boat-7-float"] },
            { value: "3", animation: ["Boat-7-float"] },
            { value: "4", animation: ["Boat-7-float"] },
            { value: "5", animation: ["Boat-7-float"] },
            { value: "6", animation: ["Boat-7-float"] },
            { value: "7", animation: ["Boat-7-float"] },
            { value: "8", animation: ["Boat-7-float"] },
            { value: "9", animation: ["Boat-7-float"] },
            { value: "10", animation: ["Boat-7-float"] },
            { value: "11", animation: ["Boat-7-float"] },
            { value: "12", animation: ["Boat-7-float"] }
        ]
    },
    {
        name: "raft-2",
        animations: [
            { value: "0", animation: ["raft-2-float"] },
            { value: "1", animation: ["raft-2-float"] },
            { value: "2", animation: ["raft-2-float"] },
            { value: "3", animation: ["raft-2-float"] },
            { value: "4", animation: ["raft-2-float"] },
            { value: "5", animation: ["raft-2-float"] },
            { value: "6", animation: ["raft-2-float"] },
            { value: "7", animation: ["raft-2-float"] },
            { value: "8", animation: ["raft-2-float"] },
            { value: "9", animation: ["raft-2-float"] },
            { value: "10", animation: ["raft-2-float"] },
            { value: "11", animation: ["raft-2-float"] },
            { value: "12", animation: ["raft-2-float"] }
        ]
    },
    {
        name: "boat-4",
        animations: [
            { value: "0", animation: ["boat-4-float"] },
            { value: "1", animation: ["boat-4-float"] },
            { value: "2", animation: ["boat-4-float"] },
            { value: "3", animation: ["boat-4-float"] },
            { value: "4", animation: ["boat-4-float"] },
            { value: "5", animation: ["boat-4-float"] },
            { value: "6", animation: ["boat-4-float"] },
            { value: "7", animation: ["boat-4-float"] },
            { value: "8", animation: ["boat-4-float"] },
            { value: "9", animation: ["boat-4-float"] },
            { value: "10", animation: ["boat-4-float"] },
            { value: "11", animation: ["boat-4-float"] },
            { value: "12", animation: ["boat-4-float"] }
        ]
    },
    {
        name: "palm1",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm2",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm3",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm4",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm5",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm6",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm7",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "palm8",
        animations: [
            { value: "0", animation: ["1_idle"] },
            { value: "1", animation: ["1_idle"] },
            { value: "2", animation: ["1_idle"] },
            { value: "3", animation: ["2_slow"] },
            { value: "4", animation: ["2_slow"] },
            { value: "5", animation: ["3_normal"] },
            { value: "6", animation: ["3_normal"] },
            { value: "7", animation: ["3_normal"] },
            { value: "8", animation: ["4_fast"] },
            { value: "9", animation: ["4_fast"] },
            { value: "10", animation: ["4_fast"] },
            { value: "11", animation: ["5_storm"] },
            { value: "12", animation: ["5_storm"] }
        ]
    },
    {
        name: "tradetent1",
        animations: [
            { value: "0", animation: ["TradeTentDonkeyIdle"] },
            { value: "1", animation: ["TradeTentDonkeyIdle"] },
            { value: "2", animation: ["TradeTentDonkeyIdle"] },
            { value: "3", animation: ["TradeTentDonkeyIdle"] },
            { value: "4", animation: ["TradeTentDonkeyIdle"] },
            { value: "5", animation: ["TradeTentDonkeyIdle"] },
            { value: "6", animation: ["TradeTentDonkeyIdle"] },
            { value: "7", animation: ["TradeTentDonkeyIdle"] },
            { value: "8", animation: ["TradeTentDonkeyIdle"] },
            { value: "9", animation: ["TradeTentDonkeyIdle"] },
            { value: "10", animation: ["TradeTentDonkeyIdle"] },
            { value: "11", animation: ["TradeTentDonkeyIdle"] },
            { value: "12", animation: ["TradeTentDonkeyIdle"] }
        ]
    },
    {
        name: "waterWave1",
        animations: [
            { value: "0", animation: ["animation"] },
            { value: "1", animation: ["animation"] },
            { value: "2", animation: ["animation"] },
            { value: "3", animation: ["animation"] },
            { value: "4", animation: ["animation"] },
            { value: "5", animation: ["animation"] },
            { value: "6", animation: ["animation"] },
            { value: "7", animation: ["animation"] },
            { value: "8", animation: ["animation"] },
            { value: "9", animation: ["animation"] },
            { value: "10", animation: ["animation"] },
            { value: "11", animation: ["animation"] },
            { value: "12", animation: ["animation"] }
        ]
    },
    {
        name: "waterWave2",
        animations: [
            { value: "0", animation: ["animation"] },
            { value: "1", animation: ["animation"] },
            { value: "2", animation: ["animation"] },
            { value: "3", animation: ["animation"] },
            { value: "4", animation: ["animation"] },
            { value: "5", animation: ["animation"] },
            { value: "6", animation: ["animation"] },
            { value: "7", animation: ["animation"] },
            { value: "8", animation: ["animation"] },
            { value: "9", animation: ["animation"] },
            { value: "10", animation: ["animation"] },
            { value: "11", animation: ["animation"] },
            { value: "12", animation: ["animation"] }
        ]
    },
    {
        name: "waterWave3",
        animations: [
            { value: "0", animation: ["animation"] },
            { value: "1", animation: ["animation"] },
            { value: "2", animation: ["animation"] },
            { value: "3", animation: ["animation"] },
            { value: "4", animation: ["animation"] },
            { value: "5", animation: ["animation"] },
            { value: "6", animation: ["animation"] },
            { value: "7", animation: ["animation"] },
            { value: "8", animation: ["animation"] },
            { value: "9", animation: ["animation"] },
            { value: "10", animation: ["animation"] },
            { value: "11", animation: ["animation"] },
            { value: "12", animation: ["animation"] }
        ]
    },
    {
        name: "waterWave4",
        animations: [
            { value: "0", animation: ["animation"] },
            { value: "1", animation: ["animation"] },
            { value: "2", animation: ["animation"] },
            { value: "3", animation: ["animation"] },
            { value: "4", animation: ["animation"] },
            { value: "5", animation: ["animation"] },
            { value: "6", animation: ["animation"] },
            { value: "7", animation: ["animation"] },
            { value: "8", animation: ["animation"] },
            { value: "9", animation: ["animation"] },
            { value: "10", animation: ["animation"] },
            { value: "11", animation: ["animation"] },
            { value: "12", animation: ["animation"] }
        ]
    }
];

// Define a function to update spine asset animations based on weather
export function updateSpineAnimationsForWeather(container: Container, tilemap: TiledMap, weather: number) {
    // Iterate through each layers container within the main container
    container.children.forEach(layers => {
        // Check if the current layers container has children
        if (layers.children) {
            // Iterate through the children within the current layers container
            layers.children.forEach(child => {
                // Check if the child is an instance of Spine
                if (child instanceof Spine) {
                    const assetName = child.name;
                    // Find the corresponding animated asset data based on assetName
                    const animatedAsset = ANIMATED_ASSETS.find(aa => aa.name === assetName);
                    if (animatedAsset) {
                        // Find the scenario animations for the provided weather
                        const scenarioAnimations = animatedAsset.animations.find(animation => animation.value === String(weather));
                        if (scenarioAnimations) {
                            // Get the array of animations for the scenario
                            const animationsToPlay = scenarioAnimations.animation;
                            let currentAnimationIndex = 0;
                            const playNextAnimation = () => {
                                const animationName = animationsToPlay[currentAnimationIndex];
                                if (animationName) {
                                    // Add the animation to play in the Spine state
                                    child.state.addAnimation(0, animationName, false, 0);
                                    currentAnimationIndex++;
                                } else {
                                    // Play the last animation in a loop
                                    child.state.addAnimation(0, animationsToPlay[currentAnimationIndex - 1], true, 0);
                                }
                            };
                            // Set up an event listener for animation completion
                            child.state.addListener({
                                complete: playNextAnimation
                            });
                            playNextAnimation(); // Start playing the animations
                            child.update(Math.random());
                        } else {
                            console.log(`Weather = ${weather} but animation not found for ${assetName}.`);
                        }
                    }
                }
            });
        }
    });
}

/**
 * Restarts the animations for a specific Spine asset based on weather conditions.
 * Iterates through layers and children in the container and updates animations
 * for the specified asset name when it matches the provided asset name for update.
 *
 * @param container - The container containing the layers and Spine assets.
 * @param tilemap - The TiledMap object.
 * @param weather - The current weather condition.
 * @param assetNameForUpdate - The asset name to update the animations for.
 */
export function restartAnimation(container: Container, tilemap: TiledMap, weather: number, assetNameForUpdate: string) {
    // Iterate through each layers container within the main container
    container.children.forEach(layers => {
        // Check if the current layers container has children
        if (layers.children) {
            // Iterate through the children within the current layers container
            layers.children.forEach(child => {
                // Check if the child is an instance of Spine
                if (child instanceof Spine) {
                    const assetName = child.name;
                    // Find the corresponding animated asset data based on assetName
                    const animatedAsset = ANIMATED_ASSETS.find(aa => aa.name === assetName);
                    // Check if the asset name matches the provided name for update
                    if (assetNameForUpdate === assetName) {
                        if (animatedAsset) {
                            // Find the scenario animations for the provided weather
                            const scenarioAnimations = animatedAsset.animations.find(animation => animation.value === String(weather));
                            if (scenarioAnimations) {
                                // Get the array of animations for the scenario
                                const animationsToPlay = scenarioAnimations.animation;
                                let currentAnimationIndex = 0;
                                // Function to play the next animation in sequence
                                const playNextAnimation = () => {
                                    const animationName = animationsToPlay[currentAnimationIndex];
                                    if (animationName) {
                                        // Set the animation to play in the Spine state
                                        child.state.setAnimation(0, animationName, false);
                                        currentAnimationIndex++;
                                    } else {
                                        // Play the last animation in a loop
                                        child.state.setAnimation(0, animationsToPlay[currentAnimationIndex - 1], true);
                                    }
                                };
                                // Set up an event listener for animation completion
                                child.state.addListener({
                                    complete: playNextAnimation
                                });
                                playNextAnimation(); // Start playing the animations
                                child.update(Math.random());
                            } else {
                                console.log(`Weather = ${weather} but animation not found for ${assetName}.`);
                            }
                        } else {
                            console.log(`Asset ${assetName} not found.`);
                        }
                    }
                }
            });
        }
    });
}

function isoToOrto(isoX: number, isoY: number) {
    return {
        x: isoX - isoY,
        y: (isoX + isoY) / 2,
    };
}

function placeTile(
    tilemap: TiledMap,
    ortX: number,
    ortY: number,
    tilesetGid: number,
    tileWidth: number,
    tileHeight: number,
    basePath: string,
    cont: any,
    pathToTexture: Record<string, Texture>,
    pixiTilemap: any,
    weather: number,
    index: number
) {
    // Find the tileset that contains the specified GID.
    const tileset = tilemap.tilesets.find(
        (t) =>
            tilesetGid >= t.firstgid && tilesetGid < t.firstgid + t.tilecount
    );

    // Throw an error if the tileset is undefined.
    if (!tileset) {
        throw new Error("Undefined tileset #" + tilesetGid);
    }

    // Adjust the Y-coordinate for the tileset's position.
    ortY -= tileset.tileheight;

    // Adjust the coordinates for staggered tilemaps.
    ortX -= tilemap.tilewidth / 2;
    ortY -= tilemap.tileheight / 2;

    // Calculate the column and row of the tile in the tileset.
    const col = (tilesetGid - tileset.firstgid) % tileset.columns;
    const row = Math.floor((tilesetGid - tileset.firstgid) / tileset.columns);

    // If the tile is not animated, add it to the CompositeTilemap using the texture and coordinates.

    // Check if the tileset has additional tiles and is not WaterSand1 tileset.
    if (tileset.tiles && tileset.tiles.length && tileset.name !== "WaterSand1") {
        const imageId = tilesetGid - tileset.firstgid;
        const tile = tileset.tiles[imageId];

        if (tile && tile.image) {
            // Adjust the coordinates for staggered tile not tilemap!
            ortY += tileset.tileheight;
            ortY -= tileHeight;

            // Check if the tile is an animated asset.
            const animatedAsset = ANIMATED_ASSETS.find((aa) => {
                const sameImage = aa.name === baseName(tile.image);
                return sameImage;
            });

            index++;
            if (animatedAsset) {
                // Get the asset name and necessary tile properties
                const skel = animatedAsset.name + ".skel";
                const localIndex = index;
                const localTileScale = tileWidth / tileset.tiles[tilesetGid - tileset.firstgid].imagewidth;
                const localName = animatedAsset.name;

                // Find the scenario animations for the provided weather
                const scenarioAnimations = animatedAsset.animations.find(
                    animation => animation.value === String(weather)
                );

                if (scenarioAnimations) {
                    const animationsToPlay = scenarioAnimations.animation;

                    // Load the Spine asset data and create a new Spine instance
                    Assets.load("/assets/animations/" + skel).then(assetData => {
                        const spineAsset = new Spine(assetData.spineData);
                        // Set initial position and scale for the Spine asset
                        spineAsset.x = ortX;
                        spineAsset.y = ortY;
                        spineAsset.scale.x = localTileScale;
                        spineAsset.scale.y = localTileScale;
                        spineAsset.name = localName;
                        spineAsset.zIndex = localIndex;

                        let currentAnimationIndex = 0;

                        // Function to play the next animation in the scenario
                        const playNextAnimation = () => {
                            const animationName = animationsToPlay[currentAnimationIndex];

                            if (animationName) {
                                // Add the animation to play in the Spine state
                                spineAsset.state.addAnimation(0, animationName, false, 0);
                                currentAnimationIndex++;
                            } else {
                                // Play the last animation in a loop
                                spineAsset.state.addAnimation(0, animationsToPlay[currentAnimationIndex - 1], true, 0);
                            }
                        };

                        // Event listener for animation completion
                        spineAsset.state.addListener({
                            complete: playNextAnimation
                        });

                        playNextAnimation(); // Start playing the animations
                        spineAsset.update(Math.random());
                        cont.addChild(spineAsset); // Add the Spine asset to the container
                    });
                } else {
                    console.log("Weather = " + weather + " but animation not found.");
                }
            } else {
                const texture = Texture.from(basePath + tile.image);

                // Add the tile as a sprite to the CompositeTilemap.
                const sprite = new Sprite(texture);
                sprite.x = ortX;
                sprite.y = ortY;
                sprite.zIndex = index;
                sprite.width = tileWidth;
                sprite.height = tileHeight;
                cont.addChild(sprite);
            }
        }
    } else {
        // Add the tile to the CompositeTilemap using the texture and coordinates.
        if (pathToTexture[tileset.image]) {
            pixiTilemap.tile(pathToTexture[tileset.image], ortX, ortY, {
                u: col * tileset.tilewidth,
                v: row * tileset.tileheight,
                tileWidth: tileset.tilewidth,
                tileHeight: tileset.tileheight,
            });
        }
    }
};

export async function tiledToPixi(stage: Container, tilemap: TiledMap, basePath: string, weather: number) {
    // Check if the tilemap is infinite and throw an error if it is.
    if (tilemap.infinite) {
        throw new Error("Infinite maps not supported!");
    }

    // Replace the incorrect base path with the correct one in tilemap.tilesets
    tilemap.tilesets.forEach((tileset) => {
        if (tileset.image) {
            tileset.image = tileset.image.replace(/(.*\/Assets)/, "../../../../../assets/new_scenes/Assets");
        }

        if (tileset.tiles && tileset.tiles.length) {
            tileset.tiles.forEach((tile) => {
                if (tile.image) {
                    tile.image = tile.image.replace(/(.*\/Assets)/, "../../../../../assets/new_scenes/Assets");
                }
            });
        }
    });

    // Create a dictionary to store the path to each texture.
    const pathToTexture: Record<string, Texture> = {};

    // Load and store each tileset's image as a texture.
    const tilesetPromises = tilemap.tilesets.map(async (tileset) => {

        if (tileset.image) {
            // Check if the texture is already in the cache.
            const textureId = basePath + tileset.image;
            const texture = Texture.from(textureId);
            pathToTexture[tileset.image] = texture;

            if (texture && !texture.baseTexture.valid) {
                // Load the tileset image using the Assets.load() function (assuming it's an asynchronous operation).
                await Assets.load(basePath + tileset.image);
                // Create a new texture and store it in the cache.
                // Store the texture in the dictionary using the image path as the key.
            }
        }

        // Check if the tileset has additional tiles.
        if (tileset.tiles && tileset.tiles.length) {
            const tilePromises = tileset.tiles
                .filter((tile) => tile.image)
                .map(async (tile) => {
                    // Check if the texture is already in the cache.
                    const tileTextureId = basePath + tile.image;
                    const tileTexture = Texture.from(tileTextureId);

                    if (!tileTexture.baseTexture.valid && tile.image) {
                        // Load the additional tile image using the Assets.load() function.
                        // await Assets.load(basePath + tile.image);
                    }
                });
            await Promise.all(tilePromises);
        }
    });

    await Promise.all(tilesetPromises);

    // Create a Set to keep track of animated tilemaps.
    const animatedTilemaps = new Set<CompositeTilemap>();

    // Iterate through each layer in the tilemap.
    for (const layer of tilemap.layers) {
        // Skip layers that are not visible.
        if (!layer.visible) {
            continue;
        }

        // Create a container for the layer and add it to the PIXI application's stage.
        const cont = new Container();
        cont.sortableChildren = true;
        stage.addChild(cont);

        // Create a CompositeTilemap for the layer and add it to the container.
        const pixiTilemap = new CompositeTilemap();
        cont.addChild(pixiTilemap);

        // Apply opacity to the container if the layer has opacity less than 1.
        if (layer.opacity < 1) {
            cont.filters = [new AlphaFilter(layer.opacity)];
        }

        // Define a function to place a tile at the specified coordinates with the given tileset GID.
        let index = 0;

        // Process the layer based on its type.
        if (layer.type === "tilelayer") {
            // Iterate through each tile in the layer.
            for (let y = 0; y < layer.height; ++y) {
                for (let x = 0; x < layer.width; ++x) {
                    const tileIndex = y * layer.width + x;
                    const tilesetGid = layer.data[tileIndex];

                    // Skip tiles with GID less than or equal to 0 (no tile).
                    if (tilesetGid <= 0) {
                        continue;
                    }

                    let ortX, ortY;

                    // Adjust the coordinates based on the tilemap's orientation.
                    if (tilemap.orientation === "staggered") {
                        ortX = x * tilemap.tilewidth;
                        ortY = y * tilemap.tileheight / 2;

                        if (y % 2 === 1) {
                            ortX += tilemap.tilewidth / 2;
                        }
                    } else if (tilemap.orientation === "isometric") {
                        ortX = (x - y) * tilemap.tilewidth / 2;
                        ortY = (y + x) * tilemap.tileheight / 2;
                    } else {
                        throw new Error("Unsupported orientation: " + tilemap.orientation);
                    }

                    // Adjust the Y-coordinate.
                    ortY += tilemap.tileheight;
                    index++;
                    // Place the tile at the calculated coordinates.
                    placeTile(tilemap, ortX, ortY, tilesetGid, tilemap.tilewidth, tilemap.tileheight, basePath, cont, pathToTexture, pixiTilemap, weather, index);
                }
            }
        } else if (layer.type === "objectgroup") {
            // Sort the objects in the layer based on their Y-coordinate.
            const objects = [...layer.objects].sort((a, b) => a.y - b.y);

            // Iterate through each object in the layer.
            for (const object of objects) {
                if (object.gid) {
                    let x, y, width, height, scale;

                    // Adjust the coordinates based on the tilemap's orientation.
                    if (tilemap.orientation === "staggered") {
                        x = object.x;
                        y = object.y;
                    } else if (tilemap.orientation === "isometric") {
                        ({ x, y } = isoToOrto(object.x, object.y));
                    } else {
                        throw new Error("Unsupported orientation: " + tilemap.orientation);
                    }
                    width = object.width;
                    height = object.height;
                    index++;
                    // Place the tile at the calculated coordinates.
                    placeTile(tilemap, x, y, object.gid, width, height, basePath, cont, pathToTexture, pixiTilemap, weather, index);
                }
            }
        }
    }

    // Add a ticker to animate the tilemaps.
    // if (animatedTilemaps.size > 0) {
    //     let time = 0;

    //     app.ticker.add((delta) => {
    //         time += delta;
    //         const frame = Math.floor(time / 24);

    //         // Update the tile animation frame for each animated tilemap.
    //         for (const tilemap of animatedTilemaps) {
    //             tilemap.tileAnim = [frame, frame];
    //         }
    //     });
    // }
}

