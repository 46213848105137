<template>
    <div ref="scenesWrapper"  class="scenesWrapper" v-loading="loading">
        <div class="scene-controls-panel">
            <label>
                <span>Weather</span>
                <input v-model="weather" class="scale-input" type="number" step="1" min="0" max="12" />
            </label>
            <label>
                <span>Scale</span>
                <input v-model="scale" class="scale-input" type="number" step="0.1" min="0.05" max="1" @change="updateScaleFromEvent"/>
            </label>
            x: {{ log }}
            a: {{ accumulatedDistanceDiff }}
        </div>
        <div class="scenes-scroll-wrapper">
            <div
                id="scenesScroll"
                ref="scenesScroll"
                class="scenes-scroll"
                :class="{ 'night-mode': isNightModeOn }"
                @mousedown.left="mouseDown"
                @mouseup.left="mouseUp"
                @mousemove="onMouseMove"
                @touchstart="onTouchStart"
                @touchmove="onTouchMove"
                @touchend="onTouchEnd"
                @wheel.ctrl.exact="onWheel"
            >
                <div class="scenes-container">
                    <div ref="pixiContainer" class="pixiContainer" id="pixiContainer"></div>
                </div>
            </div>
            <div id="effects" ref="effects">
                <div v-if="isLightningModeOn" :class="{'lightning-filter-overlay': isLightningModeOn}" id="lightningElement" class="lightningElement" ></div>
                <template v-if="rainType == 1">
                    <video class="rain-video" v-show="isLightningModeOn" autoplay muted loop playsinline>
                        <source :src="`/assets/video/rain1.mov`" type="video/mp4" />
                        <source :src="`/assets/video/rain1.webm`" type="video/webm" />
                    </video>
                </template>
                <template v-if="rainType == 2">
                    <video class="rain-video" v-show="isLightningModeOn" autoplay muted loop playsinline>
                        <source :src="`/assets/video/rain2.mov`" type="video/mp4" />
                        <source :src="`/assets/video/rain2.webm`" type="video/webm" />
                    </video>
                </template>

                <template v-if="rainType == 3">
                    <video class="rain-video" v-show="isLightningModeOn" autoplay muted loop playsinline>
                        <source :src="`/assets/video/rain3.mov`" type="video/mp4" />
                        <source :src="`/assets/video/rain3.webm`" type="video/webm" />
                    </video>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import { Container, Renderer, Ticker } from "pixi.js";
    import type TiledMap from "tiled-tmj-typedefs/types/TiledMap";
    import type { PropType } from "vue";
    import PearlApiService from "~/composables/PearlApiService";
    import { restartAnimation, tiledToPixi } from "~/composables/utils/animations";
    import { updateSpineAnimationsForWeather } from "~/composables/utils/animations";
    import { AdjustmentFilter } from "@pixi/filter-adjustment";

    interface Point {
        x: number;
        y: number;
    }

    export default defineComponent({
        props: {
            tilemap: {
                required: true,
                type: Object as PropType<TiledMap>,
            },
            animation: {
                required: false,
                default: "",
                type: String,
            }
        },
        emits: ["resetAnimation"],
        data(): {
            loading: boolean;
            isNightModeOn: boolean;
            isLightningModeOn: boolean;
            lightningThresholdA: number;
            lightningThresholdB: number;
            app: { renderer: Renderer; stage: Container; ticker: Ticker };
            sceneCanvas: HTMLCanvasElement;
            imgLeft: number;
            imgTop: number;
            mouseX: number;
            mouseY: number;
            hold: boolean;
            scale: number;
            lightning1: number;
            lightning2: number;
            scriptIsRunning: boolean;
            lastTouchY: number;
            lastTouchX: number;
            deltaY: number;
            firstDist: number;
            weather: number;
            startX: number;
            startY: number;
            rainType: number;
            stage: any | null;
            sceneSaturation: number;
            lastDistance: number;
            accumulatedDistanceDiff: number;
            scaleMode: boolean;
            log: number;
        } {
            return {
                loading: false,
                isNightModeOn: false,
                isLightningModeOn: false,
                lightningThresholdA: 9980,
                lightningThresholdB: 9970,
                app: null!,
                sceneCanvas: null!,
                imgLeft: 0,
                imgTop: 0,
                mouseX: 0,
                mouseY: 0,
                hold: false,
                scale: 0.5,
                lightning1: 0,
                lightning2: 0,
                scriptIsRunning: false,
                lastTouchY: 0,
                lastTouchX: 0,
                deltaY: 0,
                firstDist: 0,
                weather: 0,
                startX: 0,
                startY: 0,
                rainType: 0,
                stage: null,
                sceneSaturation: 0.1,
                scaleMode: false,
                lastDistance: 0,
                accumulatedDistanceDiff: 0,
                log: 0,
            };
        },
        deactivated() {
            console.log("deactivated");
            this.app.ticker.destroy();
            this.app.renderer.destroy();
            this.app.stage.destroy();
        },
        onBeforeUnmount() {
            console.log("onBeforeUnmount");
            this.app.ticker.destroy();
            this.app.renderer.destroy();
            this.app.stage.destroy();
        },
        beforeDestroy() {
            alert("the component is destroy");
            this.app.ticker.destroy();
            this.app.renderer.destroy();
            this.app.stage.destroy();
        },
        async mounted() {

            function fixPosition() {
                const effects = (<any>document).getElementById("effects") as HTMLElement;;
                const scroll = (<any>document).getElementById("scenesScroll") as HTMLElement;
                const gameComponent = (<any>document).getElementById("pixiContainer") as HTMLElement;

                // center
                if (gameComponent.clientWidth < scroll.clientWidth) {
                    const left = (scroll.clientWidth - gameComponent.clientWidth) / 2;
                    effects.style.left = left + "px";
                    effects.style.width = gameComponent.clientWidth + "px";

                    // Update container styles based on new positions
                    gameComponent.style.left = Math.round(left) + "px";
                } else {
                    effects.style.left = "";
                    effects.style.width = "";
                }

                if (gameComponent.clientHeight < scroll.clientHeight) {
                    const top = (scroll.clientHeight - gameComponent.clientHeight) / 2;
                    effects.style.top = top + "px";
                    effects.style.height = gameComponent.clientHeight + "px";
                } else {
                    effects.style.top = "";
                    effects.style.height = "";
                }
            }
            if (window) {
                window.onresize = fixPosition;
            }

            // check if we have old app
            if (window && (<any>window).app && (<any>window).app.destroy) {
                (<any>window).app.destroy();
            }

            // Set loading state
            this.loading = true;

            // Get canvas element and define initial scale
            this.sceneCanvas = this.$refs.sceneCanvas as HTMLCanvasElement;
            let scale = 0.5;

            // Adjust scale based on window width
            if (window) {
                if (window.innerWidth < 900) {
                    scale = 0.25;
                }
            }

            // Create Pixi.js application instance with adjusted dimensions
            // const app = new Application<HTMLCanvasElement>({
            //     width: this.tilemap.width * this.tilemap.tilewidth * scale,
            //     height: this.tilemap.height * this.tilemap.tileheight * scale,
            // });

            const renderer = new Renderer({
                width: this.tilemap.width * this.tilemap.tilewidth * scale,
                height: this.tilemap.height * this.tilemap.tileheight * scale,
            });

            const stage = new Container();
            this.stage = stage;

            // Get the container element and append the Pixi.js view
            const pixiContainer = this.$refs.pixiContainer as HTMLElement;
            pixiContainer.appendChild(renderer.view as HTMLCanvasElement);

            const ticker = new Ticker();
            ticker.maxFPS = 48;

            ticker.add((delta) => {
                renderer.render(stage);
            });
            ticker.start();


            // Store the Pixi.js app instance
            this.app = {
                renderer,
                stage,
                ticker,
            };

            // Set to so user can scroll page (touching canvas) on mobile
            this.app.renderer.plugins.interaction.autoPreventDefault = false;
            if (this.app.renderer.view.style) {
                this.app.renderer.view.style.touchAction = "auto";
            }

            // Update scale and fetch weather information
            this.updateScale(scale);
            await this.fetchWeather();

            // Convert and display Tiled map using Pixi.js
            await tiledToPixi(stage, this.tilemap, "/assets/new_scenes/", this.weather);

            // Set loading state to false
            this.loading = false;

            // Expose the Pixi.js app instance to the global window object
            if (window) {
                (<any>window).app = this.app;
            }

            // Apply weather effects
            this.weatherEffects();

            // Set lightning effect
            this.setLightningEffect();

            // Add event listener for mouseup event
            const self = this;
            if (document) {
                document.addEventListener("mouseup", function (event) {
                    self.mouseUp(event);
                });
            }
        },
        watch: {
            // Watch for changes in the weather value
            weather(newValue, oldValue) {
                // Apply weather-specific effects
                this.weatherEffects();
                // Check if the app instance is of type Application (Pixi.js)
                if (this.app.renderer && this.app.stage && this.app.ticker) {
                    const stage = this.app.stage as Container;
                    updateSpineAnimationsForWeather(stage, this.tilemap, this.weather);
                }
            },
            animation(newValue, oldValue) {
                if (newValue && this.stage) {
                    console.log("run animation", newValue);
                    restartAnimation(this.stage, this.tilemap, this.weather, newValue);
                    this.$emit("resetAnimation");
                }
            }
        },
        methods: {
            weatherEffects() {
                // Apply weather effects based on weather conditions
                // Clear and calm weather conditions
                if (this.weather == 0) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 1;
                }
                if (this.weather == 1) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.98;
                }
                if (this.weather == 2) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.95;
                }
                // Mild weather conditions
                if (this.weather == 3) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.9;
                }
                if (this.weather == 4) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.9;
                }
                if (this.weather == 5) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.85;
                }
                if (this.weather == 6) {
                    this.isLightningModeOn = false;
                    this.sceneSaturation = 0.8;
                }
                // Rainy weather conditions
                if (this.weather == 7) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9997;
                    this.lightningThresholdB = 9995;
                    this.rainType = 1;
                    this.sceneSaturation = 0.75;
                }
                if (this.weather == 8) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9997;
                    this.lightningThresholdB = 9995;
                    this.rainType = 1;
                    this.sceneSaturation = 0.7;
                }
                // Heavy rain weather conditions
                if (this.weather == 9) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9992;
                    this.lightningThresholdB = 9987;
                    this.rainType = 2;
                    this.sceneSaturation = 0.5;
                }
                if (this.weather == 10) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9992;
                    this.lightningThresholdB = 9987;
                    this.rainType = 2;
                    this.sceneSaturation = 0.4;
                }
                // Heavy rain weather conditions
                if (this.weather == 11) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9992;
                    this.lightningThresholdB = 9987;
                    this.rainType = 2;
                    this.sceneSaturation = 0.3;
                }
                // Stormy weather conditions
                if (this.weather == 12) {
                    this.isLightningModeOn = true;
                    this.lightningThresholdA = 9983;
                    this.lightningThresholdB = 9975;
                    this.rainType = 3;
                    this.sceneSaturation = 0.2;
                }

                const saturationFilter = new AdjustmentFilter({
                    saturation: this.sceneSaturation,
                });

                this.stage.filters = [saturationFilter];
            },
            // Calculate the maximum scale based on window size
            maxScale() {
                if (!window) {
                    return 1; // Return default scale if window is not available
                }
                return (window.innerWidth < 900) ? 0.6 : 1; // Adjust scale based on window width
            },
            // Calculate the minimum scale based on window size
            minScale() {
                if (!window) {
                    return 0.5; // Return default scale if window is not available
                }
                return (window.innerWidth < 900) ? 0.25 : 0.5; // Adjust scale based on window width
            },
            // Fetch weather data asynchronously
            async fetchWeather() {
                try {
                    // Retrieve weather level from PearlApiService
                    const res = await PearlApiService.getTakeProfitLevel();
                    this.weather = res.level; // Update weather data
                } catch (error: unknown) {
                    console.error(error); // Log any errors that occur during fetching
                }
            },
            // Update mouse position on mouse move event
            onMouseMove(event: MouseEvent) {
                this.mouseX = event.offsetX;
                this.mouseY = event.offsetY;

                // Get references to game container and scroll container
                const gameComponent = this.$refs.pixiContainer as HTMLElement;
                const scroll = this.$refs.scenesScroll as HTMLElement;;

                if (this.hold) {
                    // Calculate delta values for image position
                    let deltaX = this.imgLeft - (this.startX - event.clientX);
                    let deltaY = this.imgTop - (this.startY - event.clientY);

                    // Ensure that deltaY doesn't exceed 0 (image should not move upwards beyond its initial position)
                    if (deltaY > 0) {
                        deltaY = 0;
                    }

                    // Ensure that deltaX doesn't exceed 0 (image should not move leftwards beyond its initial position)
                    if (deltaX > 0) {
                        deltaX = 0;
                    }

                    // Calculate maximum Y position to prevent image from moving downward beyond the container's bottom
                    const maxY = -gameComponent.clientHeight + scroll.clientHeight;
                    if (maxY > deltaY) {
                        deltaY = maxY;
                    }

                    // Calculate maximum X position to prevent image from moving rightward beyond the container's right edge
                    const maxX = -gameComponent.clientWidth + scroll.clientWidth;

                    if (maxX > deltaX) {
                        if (gameComponent.clientWidth <= scroll.clientWidth) {
                            deltaX = 0;
                        } else {
                            deltaX = maxX;
                        }
                    }

                    // center x
                    if (gameComponent.clientWidth <= scroll.clientWidth) {
                        deltaX = (scroll.clientWidth - gameComponent.clientWidth) / 2;

                    }

                    // center y
                    if (gameComponent.clientHeight <= scroll.clientHeight) {
                        deltaY = (scroll.clientHeight - gameComponent.clientHeight) / 2;
                    }

                    // Update game container's top and left styles to move the image
                    gameComponent.style.top = deltaY + "px";
                    gameComponent.style.left = deltaX + "px";
                }
            },
            // Set the top position of the image with rounded value
            setTop(value: number) {
                const fixValue = Math.round(value);
                this.imgTop = fixValue;
            },
            // Set the left position of the image with rounded value
            setLeft(value: number) {
                const fixValue = Math.round(value);
                this.imgLeft = fixValue;
            },
            // Handle wheel event for zooming
            onWheel(event: WheelEvent) {
                event.stopPropagation();
                event.preventDefault();

                // Calculate zoom factor based on wheel event
                const wheelScale = event.deltaY * -0.01;
                let newScale = this.scale;

                // Adjust the scale based on the direction of the wheel scroll
                if (wheelScale > 1) {
                    newScale += 0.05;
                } else {
                    newScale -= 0.05;
                }

                // Ensure that the new scale is within the allowed bounds
                if (newScale > this.maxScale()) {
                    newScale = this.maxScale();
                }
                if (newScale < this.minScale()) {
                    newScale = this.minScale();
                }

                // Calculate the scale difference and update the scale
                const scaleDiff = this.scale - newScale;
                this.updateScale(newScale);
            },
            // Handle mouse up event
            mouseUp(event: MouseEvent) {
                if (this.loading) {
                    return; // Return early if loading
                }
                this.hold = false; // Release the hold
                const gameComponent = this.$refs.pixiContainer as HTMLElement;
                if (gameComponent && gameComponent.style.top) {
                    this.imgTop = parseInt(gameComponent.style.top);
                    this.imgLeft = parseInt(gameComponent.style.left);
                }
            },
            // Handle mouse down event to initiate dragging
            mouseDown(event: MouseEvent) {
                this.hold = true; // Set the hold state to true
                this.startX = event.clientX; // Store the starting X-coordinate of the click
                this.startY = event.clientY; // Store the starting Y-coordinate of the click
            },
            // Handle touch start event for multi-touch gestures
            onTouchStart(event: TouchEvent) {
                if (event.touches.length > 1) {
                    event.preventDefault();

                    // Store the last touch coordinates
                    this.lastTouchY = event.touches[0].clientY;
                    this.lastTouchX = event.touches[0].clientX;

                    // Create points for the two touches
                    const p1 = {
                        x: event.touches[0].clientX,
                        y: event.touches[0].clientY,
                    };
                    const p2 = {
                        x: event.touches[1].clientX,
                        y: event.touches[1].clientY,
                    };

                    // Calculate the initial distance between the two touches
                    const dist = this.getDistance(p1, p2);
                    this.firstDist = dist;
                }
            },
            // Calculate the center point between two points
            getCenter(p1: Point, p2: Point) {
                return {
                    x: (p1.x + p2.x) / 2,
                    y: (p1.y + p2.y) / 2,
                };
            },
            // Calculate the distance between two points using the Pythagorean theorem
            getDistance(p1: Point, p2: Point) {
                return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
            },
            onTouchEnd(event: TouchEvent) {
                this.scaleMode = false;
            },
            // Handle touch move event for multi-touch gestures
            onTouchMove(event: TouchEvent) {

                if (event.touches.length > 1) {
                    event.preventDefault();

                    // Calculate the initial distance between two touches
                    const p1 = {
                        x: event.touches[0].clientX,
                        y: event.touches[0].clientY,
                    };
                    const p2 = {
                        x: event.touches[1].clientX,
                        y: event.touches[1].clientY,
                    };
                    const dist = this.getDistance(p1, p2);

                    // Calculate the difference in distance between initial and current touches
                    const diffDistance = this.firstDist - dist;

                    // Store the current scale and references to the components
                    let newScale = this.scale;
                    const gameComponent = this.$refs.pixiContainer as HTMLElement;
                    const scroll = this.$refs.scenesScroll as HTMLElement;

                    // Threshold for triggering scaling based on distance difference
                    const diff = 30;

                    // we must move threshold distance to enter scale mode
                    if (Math.abs(diffDistance) > diff) {
                        this.scaleMode = true;
                    }

                    if (this.scaleMode) {
                        // Calculate the scale difference
                        let scaleDiff = 0;
                        const lastDistanceDif = diffDistance - this.lastDistance;

                        if (lastDistanceDif < 0) {
                            scaleDiff = (lastDistanceDif) * 0.0015;
                            this.log = scaleDiff;

                            newScale = newScale + -scaleDiff;
                        } else {
                            scaleDiff = (lastDistanceDif) * 0.0015;
                            this.log = scaleDiff;
                            newScale = newScale - scaleDiff;
                        }

                        // Ensure the new scale is within specified limits
                        if (newScale > this.maxScale()) {
                            newScale = this.maxScale();
                        }
                        if (newScale < this.minScale()) {
                            newScale = this.minScale();
                        }

                        // Calculate the difference in size and adjust the image top and left positions
                        scaleDiff = this.scale - this.deltaY;
                        const diffY = gameComponent.clientHeight * (1 + scaleDiff) - gameComponent.clientHeight;
                        const diffX = gameComponent.clientWidth * (1 + scaleDiff) - gameComponent.clientWidth;
                        const y = this.imgTop - diffY / 2;
                        const x = this.imgLeft - diffX / 2;

                        // Update image top and left positions and the container styles
                        this.imgTop = y;
                        this.imgLeft = x;
                        gameComponent.style.top = Math.round(y) + "px";
                        gameComponent.style.left = Math.round(x) + "px";
                        this.deltaY = this.scale;
                        this.updateScale(newScale);
                        this.lastDistance = diffDistance;
                        return;
                    }

                    // Calculate the change in Y and X coordinates of the touches
                    const deltaY = this.lastTouchY - event.touches[0].clientY;
                    const deltaX = this.lastTouchX - event.touches[0].clientX;

                    // Update the last touch coordinates
                    this.lastTouchY = event.touches[0].clientY;
                    this.lastTouchX = event.touches[0].clientX;

                    // Calculate the new image top and left positions
                    const y = this.imgTop + -1 * deltaY;
                    const x = this.imgLeft + -1 * deltaX;

                    // Check boundaries for the top position
                    if (y > 0) {
                        this.imgTop = 0;
                    } else if (y < -gameComponent.clientHeight + scroll.clientHeight) {
                        this.imgTop = -gameComponent.clientHeight + scroll.clientHeight;
                    } else {
                        this.imgTop = y;
                    }

                    // Check boundaries for the left position
                    if (x > 0) {
                        this.imgLeft = 0;
                    } else if (x < -gameComponent.clientWidth + scroll.clientWidth) {
                        this.imgLeft = -gameComponent.clientWidth + scroll.clientWidth;
                    } else {
                        this.imgLeft = x;
                    }

                    // Update container styles based on the new image positions
                    gameComponent.style.top = this.imgTop + "px";
                    gameComponent.style.left = this.imgLeft + "px";
                }
            },
            // Update the scale of the PIXI application and adjust its rendering properties
            updateScale(scale: number) {
                // Round the scale value and apply it to the component's state
                const fixScale = Math.round(scale * 100) / 100;
                this.scale = fixScale;

                // Calculate new width and height based on the scale
                const width = fixScale * ((this.tilemap.width * this.tilemap.tilewidth) - this.tilemap.tilewidth / 2);
                const height = fixScale * ((this.tilemap.height * this.tilemap.tileheight / 2) - this.tilemap.tileheight / 2);

                // Resize the PIXI renderer and adjust the stage scale
                this.app.renderer.resize(width, height);
                this.app.stage.scale.set(fixScale);

                // Get references to the scroll container and game component
                const scroll = this.$refs.scenesScroll as HTMLElement;
                const gameComponent = this.$refs.pixiContainer as HTMLElement;
                const effects = this.$refs.effects as HTMLElement;
                // const scroll = (<any>document).getElementById("scenesScroll") as HTMLElement;
                // const gameComponent = (<any>document).getElementById("pixiContainer") as HTMLElement;


                // Get current top and left positions
                let x = parseInt(gameComponent.style.left);
                let y = parseInt(gameComponent.style.top);


                // Ensure positions are valid numbers
                if (Number.isNaN(y) || Number.isNaN(x)) {
                    x = 0;
                    y = 0;

                    this.setLeft(x);
                    this.setTop(y);
                }

                // Check and adjust boundaries for the left position
                if (x > 0) {
                    x = 0;
                    this.setLeft(0);
                } else if (x < -gameComponent.clientWidth + scroll.clientWidth) {
                    x = -gameComponent.clientWidth + scroll.clientWidth;
                    this.setLeft(x);
                } else {
                    this.setLeft(x);
                }

                // Check and adjust boundaries for the top position
                if (y > 0) {
                    y = 0;
                    this.setTop(0);
                } else if (y < -gameComponent.clientHeight + scroll.clientHeight) {
                    y = -gameComponent.clientHeight + scroll.clientHeight;
                    this.setTop(y);
                } else {
                    this.setTop(y);
                }

                // center
                if (gameComponent.clientWidth < scroll.clientWidth) {
                    const left = (scroll.clientWidth - gameComponent.clientWidth) / 2;
                    console.log("left", left);
                    x = left;
                    this.setLeft(left);
                    effects.style.left = left + "px";
                    effects.style.width = gameComponent.clientWidth + "px";
                } else {
                    effects.style.left = "";
                    effects.style.width = "";
                }

                if (gameComponent.clientHeight < scroll.clientHeight) {
                    const top = (scroll.clientHeight - gameComponent.clientHeight) / 2;
                    y = top;
                    this.setTop(y);
                    effects.style.top = top + "px";
                    effects.style.height = gameComponent.clientHeight + "px";
                } else {
                    effects.style.top = "";
                    effects.style.height = "";
                }

                // Update container styles based on new positions
                gameComponent.style.top = Math.round(y) + "px";
                gameComponent.style.left = Math.round(x) + "px";
            },
            // Update the scale from an input event
            updateScaleFromEvent(event: Event) {
                // Get the input element
                const input = event.target as HTMLInputElement;

                // Update the scale using the input's value
                this.updateScale(+input.value);
            },
            // Set up the lightning effect by running the lightningEffect method at regular intervals
            setLightningEffect() {
                // Run lightningEffect every 20ms
                setInterval(this.lightningEffect, 20);
            },
            // Perform the lightning effect
            lightningEffect() {
                // Check if the script is already running to prevent overlapping executions
                if (this.scriptIsRunning) {
                    return false; // Return if the script is running to avoid concurrent execution
                } else {
                    this.scriptIsRunning = true; // Set the script as running
                }

                // Define maximum opacity values for different lightning elements
                const lightning1max: number = 0.78431372549019607;
                const lightning2max: number = 0.58823529411764708;
                const overallLightningMax: number = 0.88235294117647056;

                // Get lightning opacity thresholds
                const lightning1Treshold: number = this.lightningThresholdA;
                const lightning2Treshold: number = this.lightningThresholdB;

                // Generate random numbers for comparison
                const randomNumber1: number = Math.random() * 10000;
                const randomNumber2: number = Math.random() * 10000;

                // Update lightning opacity based on thresholds and random numbers
                if (randomNumber1 > lightning1Treshold) this.lightning1 = lightning1max;
                if (randomNumber2 > lightning2Treshold) this.lightning2 = lightning2max;

                // Calculate combined lightning opacity
                let lightningOpacity: number = this.lightning1 + this.lightning2;

                // Ensure the combined lightning opacity does not exceed the overall maximum
                if (lightningOpacity > overallLightningMax) {
                    this.lightning1 *= overallLightningMax / lightningOpacity;
                    this.lightning2 *= overallLightningMax / lightningOpacity;
                    lightningOpacity = this.lightning1 + this.lightning2;
                }

                // Get the lightning element from the DOM
                const element = document.getElementById("lightningElement");

                if (element) {
                    // Get the current opacity of the element
                    const currentOpacity = parseFloat(element.style.opacity);

                    // Check if the current opacity is greater than 0
                    if (currentOpacity > 0 || lightningOpacity > 0) {
                        // Adjust the opacity of the element
                        element.style.opacity = String(lightningOpacity);
                    }
                }

                // Update the lightning values for the next tick
                this.lightning1 *= 0.5;
                this.lightning1 -= 0.003921568627451;
                if (this.lightning2 > 0) this.lightning2 += 0.196078431372549 - Math.random() * 0.49019607843137247;

                // Clamp the lightning values to ensure they are within the valid range
                this.lightning1 = Math.max(0, this.lightning1);
                this.lightning2 = Math.max(0, this.lightning2);
                this.lightning1 = Math.min(1, this.lightning1);
                this.lightning2 = Math.min(1, this.lightning2);

                // Set the script as not running after completion
                this.scriptIsRunning = false;
            },
        },
    });
</script>

<style scoped lang="scss">
    input {
        color: black;
    }

    .scenes-scroll::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    .scenes-scroll-wrapper {
        height: calc(100vh - 200px);
        overflow: hidden;
        scrollbar-width: none;

        @media screen and (max-width: 991px) {
            height: calc(55vh);
        }
    }

    .pixiContainer {
        position: relative;
        line-height: 0;
        /* transition: 10ms ease all; */
    }
    .scenesWrapper {
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
        // display: none;
        background-color: #000;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    .scenes-scroll {
        height: calc(100vh - 200px);
        overflow: hidden;
        scrollbar-width: none;
        width: 100%;

        @media screen and (max-width: 991px) {
            height: calc(55vh);
        }
    }
    .scenes-container {
        position: relative;
        width: fit-content;
    }
    .night-mode canvas {
        filter: brightness(0.7) contrast(1.1);
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 500ms ease all;
        mix-blend-mode: multiply;
    }

    .lightningElement {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background-color: #ffffffa1;
    }



    .daytime-filter-overlay {
        opacity: 1;
        background-color: #1c0e9de0;
        mix-blend-mode: multiply;
        /* pointer-events: none; */
    }
    .scene-controls-panel {
        background-color: #282828;
        position: absolute;
        z-index: 1;
        top: 0;
        display: none;
    }

    /** replace ugly fat windows scrollbars with small semi-transparent ones (firefox has nice scrollbars out of the box) */
    ::-webkit-scrollbar {
        opacity: 0.5;
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(22, 22, 30, 0.646);
    }
    ::-webkit-scrollbar-track-piece {
        background-color: #1a232a6e;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(58, 62, 84);
        border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(62, 65, 112);
    }

    #effects,
    .rain-video {
        mix-blend-mode: screen;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    #effects {
        mix-blend-mode: normal;
    }

    .scale-input {
        color: #fff;
    }

</style>