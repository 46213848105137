import { ConfigInstance } from "./Config";

interface ActivityModel {
    address: string;
    transactionHash: string;
    value: number;
    type: string;
}

export interface StakeModel {
    address: string;
    stakeIdx: number;
    amount: string;
    claimed: string;
    released: boolean;
    createdAt: string;
}

export interface ActivityRecord {
    blockNumber: string;
    blockHash: string;
    blockTimestamp: string;
    transactionHash: string;
    tokenAddress: string;
    type: string;
    address: string;
    nonce: number;
    status: string;
    value: string;
}

export interface ChartDataModel {
    dates: string[];
    series: ChartSeriesModel[];
}

export interface ChartSeriesModel {
    name: string;
    data: number[];
}

export interface ChartLabelsModel {
    type: string;
    categories: string[];
}

export default class PearlApiService {

    public static async getTakeProfitLevel() {
        const response = await fetch(ConfigInstance.api + "/takeprofit/level");
        const res = await response.json();
        return res;
    }

    public static async config(api: string) {
        const response = await fetch(api + "/config");
        const res = await response.json();
        return res;
    }

    public static async pushActivity(data: ActivityModel) {
        const response = await fetch(ConfigInstance.api + "/activity", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        return res;
    }

    public static async getActivity(address: string) {
        const response = await fetch(ConfigInstance.api + "/activity?address=" + address);
        const res: ActivityRecord[] = await response.json();
        return res;
    }

    public static async getStake(address: string, offset = 0, limit = 50) {
        const response = await fetch(ConfigInstance.api + "/stake?address=" + address);
        const res: StakeModel[] = await response.json();
        return res;
    }

    public static async getStatisticRate(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/rate?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticDex(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/dex?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticSold(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/sold?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticHolder(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/holder?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticBuyback(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/buyback?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticSpent(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/spent?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }

    public static async getStatisticPressure(from: string, to: string): Promise<ChartDataModel> {
        const response = await fetch(ConfigInstance.api + `/statistics/pressure?from=${from}&until=${to}`);
        const res = await response.json();
        return res;
    }
}